import Vue from "vue";
import { Module } from "vuex";

import { api } from "@/util/axios";
import { Notification, Page } from "@/types";

type NotificationState = {
  notificationPage: Page<Notification>;
};

const notification: Module<NotificationState, unknown> = {
  namespaced: true,
  state: () => ({
    notificationPage: {
      docs: [],
      limit: 0,
      page: 0,
      pages: 0,
      sort: "",
      total: 0,
    },
  }),
  getters: {
    getNotification: (state) => (notificationId: string) =>
      state.notificationPage.docs.find((c) => c._id === notificationId),
    notificationPage: (state) => state.notificationPage,
  },
  mutations: {
    ADD_NOTIFICATION: (state, _notification) => {
      let idx = -1;
      state.notificationPage.docs.map((n, i) => {
        if (n._id === _notification._id) idx = i;
      });
      if (idx === -1) state.notificationPage.docs.push(_notification);
      else Vue.set(state.notificationPage.docs, idx, _notification);
    },
    SET_NOTIFICATION_PAGE: (state, list) => {
      state.notificationPage = list;
    },
    REMOVE_NOTIFICATION(state, notification) {
      let idx = -1;
      state.notificationPage.docs.map((n, i) => {
        if (n._id === notification._id) idx = i;
      });
      if (idx > -1) state.notificationPage.docs.splice(idx, 1);
    },
  },
  actions: {
    fetchNotification(context, params = "") {
      api
        .get(`/v1/notification${params}`)
        .then((response) => {
          context.commit("ADD_NOTIFICATION", response.data.notification);
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    fetchNotificationList(context, params = "") {
      api
        .get(`/v1/notification${params}`)
        .then((response) => {
          context.commit(
            "SET_NOTIFICATION_PAGE",
            response.data.notificationPage
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    async createNotification(context, payload) {
      return await api
        .post(`/v1/notification`, payload)
        .then((response) => {
          context.commit("ADD_NOTIFICATION", response.data.notification);
          // context.dispatch(
          //   "setToast",
          //   {
          //     title: "Success",
          //     type: "success",
          //     text: "Notification created",
          //   },
          //   { root: true }
          // );
          return response.data.notification;
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    updateNotification(
      context,
      data: { id: string; notification: Notification }
    ) {
      api
        .put(`/v1/notification/${data.id}`, data.notification)
        .then((response) => {
          context.commit("ADD_NOTIFICATION", response.data.notification);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Notification updated",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    deleteNotification(context, id) {
      api
        .delete(`/v1/notification${id}`)
        .then((response) => {
          context.commit("REMOVE_NOTIFICATION", response.data.notification);
          context.dispatch(
            "setToast",
            {
              title: "Success",
              type: "success",
              text: "Notification deleted",
            },
            { root: true }
          );
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
    bulkDeleteNotifications(context, ids) {
      api
        .delete(`/v1/notification/bulk`, { data: { notificationIds: ids } })
        .then((response) => {
          if (response.data) {
            ids.map((id: string) =>
              context.commit("REMOVE_NOTIFICATION", { _id: id })
            );
            context.dispatch(
              "setToast",
              {
                title: "Success",
                type: "success",
                text: "Notifications deleted",
              },
              { root: true }
            );
          }
        })
        .catch((error) => {
          context.dispatch(
            "setToast",
            {
              title: "Request failed!",
              type: "error",
              text: error.response?.data?.error?.message,
            },
            { root: true }
          );
        });
    },
  },
};

export default notification;
