

















































import Vue from "vue";
import { createNamespacedHelpers } from "vuex";

import notificationStoreModule from "@/store/modules/notification";

const { mapGetters: notificationGetters, mapActions: notificationActions } =
  createNamespacedHelpers("NOTIFICATIONS");

import AppBarItem from "./AppBarItem.vue";
import { Notification, User } from "@/types";
export default Vue.extend<any, any, any, any>({
  components: {
    AppBarItem,
  },
  name: "DefaultNotifications",
  data: () => ({}),
  computed: {
    ...notificationGetters(["notificationPage"]),
    user(): User {
      return this.$store.getters.user;
    },
  },
  mounted() {
    this.fetchNotificationList(`?userId=${this.user._id}`);
  },
  methods: {
    ...notificationActions([
      "fetchNotificationList",
      "bulkDeleteNotifications",
    ]),
    deleteNotifications() {
      this.bulkDeleteNotifications(
        this.notificationPage.docs.map((n: Notification) => n._id)
      );
    },
  },
  beforeCreate() {
    if (!this.$store.hasModule("NOTIFICATIONS")) {
      this.$store.registerModule("NOTIFICATIONS", notificationStoreModule);
    }
  },
  beforeDestroy() {
    this.$store.unregisterModule("NOTIFICATIONS");
  },
});
