import { render, staticRenderFns } from "./Notifications.vue?vue&type=template&id=7fa5d5ba&scoped=true&"
import script from "./Notifications.vue?vue&type=script&lang=ts&"
export * from "./Notifications.vue?vue&type=script&lang=ts&"
import style0 from "./Notifications.vue?vue&type=style&index=0&id=7fa5d5ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7fa5d5ba",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBadge } from 'vuetify/lib/components/VBadge';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
installComponents(component, {VBadge,VBtn,VIcon,VList,VListItem,VListItemContent,VListItemTitle,VMenu})
